import axios from 'axios';
import store from 'store2';
import { getTokenData } from '~/libraries/jwt';
import { apiURL, getTemporaryDriverToken } from '~/utils/urls';

const TEN_SECONDS = 10;

async function fetchToken() {
	const temporaryDriverToken = getTemporaryDriverToken();
	if (temporaryDriverToken) return temporaryDriverToken;
	const refreshToken = store.get('refreshToken');
	if (!refreshToken) throw new Error('No refresh token available');
	const tokenData = getTokenData();
	if (tokenData?.exp) {
		const now = new Date().getTime() / 1000;
		if (tokenData.exp - now > TEN_SECONDS) {
			return store.get('token');
		}
	}
	const refreshUrl = `${apiURL}/auth/refresh/driver-user`;
	const response = await axios.post(refreshUrl, {
		token: refreshToken,
	});
	const token = response?.data?.token;
	const newRefreshToken = response?.data?.refreshToken;
	store.set('token', token);
	if (newRefreshToken) {
		store.set('refreshToken', newRefreshToken);
	}
	return token;
}

export default fetchToken;
