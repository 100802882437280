const isLocalhost = !process.env.ENV_TYPE;

export const apiURL = isLocalhost
	? 'https://api.space-dev.allytransport.com.tw/restful'
	: `${window.location.protocol}//api.${window.location.hostname}/restful`;

export const imageURL = isLocalhost
	? 'https://space-dev.allytransport.com.tw/images'
	: `${window.location.protocol}//${window.location.hostname}/images`;

export const getTemporaryDriverToken = () => {
	const params = new URLSearchParams(window.location.search);
	const token = params.get('token');
	return token;
};
